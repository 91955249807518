module.exports = {
  metadata: {
    title: "turingpoint",
    description: "Website created with create-tpws-website",
    author: "https://turingpages.de",
    generator: "turingpages",
  },

  tpws: {
    environments: {
      development: {
        siteUrl: "http://localhost:9000",
      },
      staging: {
        siteUrl: "https://preview.staging.turingpointde.pulso-media.de",
      },
      production: {
        siteUrl: "https://turingpoint.de",
      },
    },
    defaultLocale: "de",
    dynamicTemplate: "./src/templates/dynamic-template.js",
    subfont: false,
    allowHtml: true,
  },

  strapi: {
    environments: {
      development: {
        url: "http://localhost:3000",
      },
      staging: {
        url: "https://staging.turingpointde.pulso-media.de",
        token:
          "6ba23efc31a997dbddd2d9df2a9764ce50e02ef9a0f2c8278c9638a0fcb6a0bf13f1bde5fafeee7fb31c746fd0a0f2ce5d9bc6e92933efbc19396a80494a1ef7218bf6c3118364bd4b01d4c5adb01b8ddc622f383e1416f33661f573e6d0958fc3661344ddeb7c471ff62d8b454738d9f1fe98ab756e68af41a754cb463e0df5",
      },
      production: {
        url: "https://turingpointde.pulso-media.de",
        token:
          "6ba23efc31a997dbddd2d9df2a9764ce50e02ef9a0f2c8278c9638a0fcb6a0bf13f1bde5fafeee7fb31c746fd0a0f2ce5d9bc6e92933efbc19396a80494a1ef7218bf6c3118364bd4b01d4c5adb01b8ddc622f383e1416f33661f573e6d0958fc3661344ddeb7c471ff62d8b454738d9f1fe98ab756e68af41a754cb463e0df5",
      },
    },
    collectionTypes: ["certificate"],
    singleTypes: [],
  },
};
